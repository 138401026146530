import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const GlassCard = ({ children }) => {

  return (
    <StyledGlassCard>
      {children}
    </StyledGlassCard>
  )
}

GlassCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlassCard


const StyledGlassCard = styled.div`
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .8);
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -ms-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: calc(20px + 30 * (100vw - 769px) / 1791);
  width: 100%;
  height: 100%;
  border-radius: 43px;
  position: relative;
  @media only screen and (min-width: 2560px){
    padding: 50px;
  }
`